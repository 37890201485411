import React from 'react'
import '../../components/css/promo.css'
import Header from '../../components/Header'
import Footer from '../../components/Footer'
import { Image } from 'react-bootstrap'
import Helmet from 'react-helmet'
import SixsikSaPapremyo from '../../images/sixsik-sa-papremyo-promo-page.jpg'

const SITE_META = [
  {
    name: 'description',
    content: 'Mabilis, Madali, Mura',
  },
  {
    name: 'keywords',
    content:
      'digipay, fintech, e-payments, bills payment, ' +
      'mobile money, e-load, e-pins, microinsurance',
  },
  {
    property: 'og:url',
    content: `https://${process.env.GATSBY_REACT_APP_HOSTNAME}/promos/SixsikSaPapremyo`,
  },
  {
    property: 'og:type',
    content: 'website',
  },
  {
    property: 'og:title',
    content:
      'SIXSIK SA PAPREMYO ANNIVERSARY PROMO (NOVEMBER 1, 2023 - DECEMBER 31, 2023',
  },
  {
    property: 'og:description',
    content:
      'Process transactions using Digipay Agent App to earn raffle entries and get a chance to win exciting prizes',
  },
  {
    property: 'og:image',
    content: SixsikSaPapremyo,
  },
]
const Mechanics = () => {
  return (
    <>
      <div className="mechanics lead text-lg ">
        <h4 className="font-weight-bold">PROMO MECHANICS</h4>
        <ol>
          <li>
            This promo is open to Standard, Distributor, and Enterprise Digipay
            Agents only.
          </li>
          <li>
            To qualify, agents should top-up a minimum amount of Php5,000.00.
          </li>
          <li>
            To earn raffle entries, agents should accumulate this number or
            value of transaction to earn 1 raffle entry for every activity
            below:
            <ol type="a">
              <li> Php20,000.00 worth of Agent Banking transactions </li>
              <li> 10 Bills Payment transactions </li>
              <li> Php5,000.00 worth of E-Load transactions </li>
              <li> Php2,000.00 worth of E-pins transactions </li>
              <li> Php15,000.00 worth of Mobile Money cash in transactions </li>
              <li>
                Php3,000.00 worth of Remittance transactions <br></br> *Example:
                20 bills payment transaction = 2 raffle entries{' '}
              </li>
            </ol>
          </li>
          <li>
            This promo will run from November 1 to December 31, 2023 only.
          </li>
          <li>
            Qualified agents will get a chance to win the following prizes:
            <ol type="a">
              <li> 2 winners of smartphones worth Php10,000.00 each </li>
              <li> 4 winners of grocery e-vouchers worth Php2,000.00 each </li>
              <li> 10 winners of Digipay credits worth Php1,000.00 each </li>
            </ol>
          </li>
          <li>There will be a total of 16 winners for this raffle promo.</li>

          <li>
            The deadline for the raffle of entries is as follows:
            <table className="table-mechanics">
              <thead className="font-weight-bold">
                <tr>
                  <th scope="col">DRAW</th>
                  <th scope="col">RAFFLE DRAW DATE</th>
                  <th scope="col">QUALIFICATION PERIOD</th>
                  <th scope="col">PRIZES</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td data-label="DRAW"> First Draw </td>
                  <td data-label="RAFFLE DRAW DATE"> December 14, 2023 </td>
                  <td data-label="QUALIFICATION PERIOD">
                    {' '}
                    November 1 to 30, 2023, 11:59 PM{' '}
                  </td>
                  <td data-label="PRIZES">
                    <ul>
                      <li> 1 winner of a smartphone worth P10,000.00 </li>
                      <li>2 winners of grocery e-voucher worth P2,000.00 </li>
                      <li> 5 winners of Digipay credits worth P1,000.00</li>
                    </ul>
                  </td>
                </tr>

                <tr>
                  <td scope="row" data-label="DRAW">
                    {' '}
                    Second Draw{' '}
                  </td>
                  <td data-label="RAFFLE DRAW DATE"> January 15, 2024 </td>
                  <td data-label="QUALIFICATION PERIOD">
                    {' '}
                    December 1 to 31, 2023, 11:59 PM{' '}
                  </td>
                  <td data-label="PRIZES">
                    <ul>
                      <li> 1 winner of a smartphone worth P10,000.00 </li>
                      <li>2 winners of grocery e-voucher worth P2,000.00</li>
                      <li> 5 winners of Digipay credits worth P1,000.00</li>
                    </ul>
                  </td>
                </tr>
              </tbody>
            </table>
            <li>Each agent can only win once.</li>
            <li>Prizes are not transferable or convertible to cash.</li>
            <li>
              Winners will be notified by Digipay via SMS, call, email, and/or
              registered mail.
            </li>
            <li>
              Prizes should be claimed sixty (60) days from the receipt of
              registered notice.
            </li>
            <li>
              Digipay reserves the right to disqualify customers from availing
              of this promotion if they are found to have committed acts of
              fraud.
            </li>
            <li>Digipay shall shoulder a 20% tax on prizes exceeding 10k. </li>
            <li>
              Digipay employees and its affiliates, including relatives up to
              the second degree of consanguinity or affinity, are disqualified
              from this promo.
            </li>
          </li>
        </ol>
      </div>
    </>
  )
}

const Promo6 = () => (
  <div>
    <Helmet title="Promos | SIXSIK SA PAPREMYO ANNIVERSARY PROMO" meta={SITE_META}>
      <html lang="en" />
    </Helmet>
    <Header />
    <div className="promo-page border">
      <h1 align="center" className="font-weight-bold text-uppercase">
        Sixsik sa papremyo anniversary promo
      </h1>
      <Image
        src={SixsikSaPapremyo}
        width="100%"
        align="center"
        className="promo-image"
      />
      <Mechanics />
    </div>
    <Footer />
  </div>
)
export default Promo6
